const DISCOUNT_REFS = ["ph-l1", "ih-l1", "r-l1", "hn-l1", "bl-l1"];
export const DISCOUNT_PERCENTAGE = 50;

export function readDiscountCookie() {
	if (typeof window === "undefined") return;

	return window.document.cookie
		.split(";")
		.filter((item) => {
			return (
				item.includes("discount-ref") &&
				DISCOUNT_REFS.includes(item.split("=")[1])
			);
		})
		.reduce((acc, val) => {
			return val.split("=")[1];
		}, null);
}
