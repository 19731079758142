import React from "react";
import Helmet from "react-helmet";

import Layout from "../components/layout";
import { CheckList, CheckListItem } from "../components/CheckList";
import StarRating from "../components/star-rating";
import TestimonialAutor from "../components/testimonial-autor";

import { getPlanPrice } from "@sidemail/shared/get-plan-price";
import {
	readDiscountCookie,
	DISCOUNT_PERCENTAGE,
} from "@sidemail/shared/read-discount-cookie";

import CheckMarkIcon from "../images/inlinesvgs/icon-checkmark.svg";
import IconArrowRight from "../images/inlinesvgs/icons/arrow-right.svg";
import nickPhoto from "../images/testimonial-quicka-nick.jpg";

function Pricing() {
	const [sendingLimit, setSendingLimit] = React.useState("1000");
	const [discountRef, setDiscountRef] = React.useState(null);

	// const plans = [1000, 10000, 40000, 70000, 100000];

	function handleSendingLimitChange({ target }) {
		setSendingLimit(target.value);
	}

	React.useEffect(() => {
		const discountRef = readDiscountCookie();

		if (discountRef) {
			setDiscountRef(discountRef);
		}
	}, []);

	const pricePlanPremium = getPlanPrice({
		billingCycle: "annually",
		planName: "premium",
		sendingLimit: Number(sendingLimit),
		discountRef,
	});

	return (
		<Layout>
			<main>
				<Helmet>
					<title>Pricing</title>
				</Helmet>

				<header id="pricingV3" className="aboveFold">
					<div className="container">
						<h1 className="title-xlarge text-dark text-center itemCenter maxWidth-800 mb-20">
							Pricing
						</h1>

						<div className="selectSendingLimitWrapper">
							<div className="grid grid--alignCenter selectSendingLimit">
								<div className="grid-cell selectSendingLimit-text">
									How many emails do you need to send monthly?
								</div>
								{/* <div className="grid-cell grid-cell--notFluid selectSendingLimit-number"> */}
								{/* 	{sendingLimit} */}
								{/* </div> */}
							</div>

							<div className="mb-40">
								<div className="switcher">
									<label>
										<input
											type="radio"
											name="sendingLimit"
											value="1000"
											checked={sendingLimit === "1000"}
											onChange={handleSendingLimitChange}
										/>
										<span className="switcher-label">1 000</span>
									</label>

									<label>
										<input
											type="radio"
											name="sendingLimit"
											value="10000"
											checked={sendingLimit === "10000"}
											onChange={handleSendingLimitChange}
										/>
										<span className="switcher-label">10 000</span>
									</label>

									<label>
										<input
											type="radio"
											name="sendingLimit"
											value="40000"
											checked={sendingLimit === "40000"}
											onChange={handleSendingLimitChange}
										/>
										<span className="switcher-label">40 000</span>
									</label>

									<label>
										<input
											type="radio"
											name="sendingLimit"
											value="70000"
											checked={sendingLimit === "70000"}
											onChange={handleSendingLimitChange}
										/>
										<span className="switcher-label">70 000</span>
									</label>

									<label>
										<input
											type="radio"
											name="sendingLimit"
											value="100000"
											checked={sendingLimit === "100000"}
											onChange={handleSendingLimitChange}
										/>
										<span className="switcher-label">100 000</span>
									</label>

									<label>
										<input
											type="radio"
											name="sendingLimit"
											value="250000"
											checked={sendingLimit === "250000"}
											onChange={handleSendingLimitChange}
										/>
										<span className="switcher-label">250 000</span>
									</label>

									<label>
										<input
											type="radio"
											name="sendingLimit"
											value="custom"
											checked={sendingLimit === "custom"}
											onChange={handleSendingLimitChange}
										/>
										<span className="switcher-label">More</span>
									</label>
								</div>
							</div>
						</div>

						<div className="pricingBoxWrapper">
							{sendingLimit === "custom" ? (
								<section className="pricingBox-plan">
									<h3 className="pricingBox-planName">Customized</h3>
									<p className="pricingBox-planDescirption">
										Available for businesses with large sending volume. Comes
										with every other feature we offer.
									</p>

									<div className="pricingBox-planPriceDetails">
										<div
											className="grid"
											style={{
												borderBottom: "1px solid #cfdee8",
												padding: 10,
											}}>
											<div>500 000 emails</div>
											<div
												className="grid-cell--fluid"
												style={{
													textAlign: "right",
												}}>
												$
												{Math.round(
													getPlanPrice({
														billingCycle: "annually",
														planName: "premium",
														sendingLimit: 500000,
														discountRef,
													}).monthlyFullPrice
												)}{" "}
												per month *
											</div>
										</div>

										<div
											className="grid"
											style={{
												borderBottom: "1px solid #cfdee8",
												padding: 10,
											}}>
											<div>750 000 emails</div>
											<div
												className="grid-cell--fluid"
												style={{
													textAlign: "right",
												}}>
												$
												{Math.round(
													getPlanPrice({
														billingCycle: "annually",
														planName: "premium",
														sendingLimit: 750000,
														discountRef,
													}).monthlyFullPrice
												)}{" "}
												per month *
											</div>
										</div>

										<div
											className="grid"
											style={{
												borderBottom: "1px solid #cfdee8",
												padding: 10,
											}}>
											<div>1 000 000 emails</div>
											<div
												className="grid-cell--fluid"
												style={{
													textAlign: "right",
												}}>
												$
												{Math.round(
													getPlanPrice({
														billingCycle: "annually",
														planName: "premium",
														sendingLimit: 1000000,
														discountRef,
													}).monthlyFullPrice
												)}{" "}
												per month *
											</div>
										</div>

										<div
											className="grid"
											style={{
												borderBottom: "1px solid #cfdee8",
												padding: 10,
											}}>
											<div>Over 1M emails</div>
											<div
												className="grid-cell--fluid"
												style={{
													textAlign: "right",
												}}>
												Custom offer
											</div>
										</div>

										<div
											class="text-small"
											style={{
												lineHeight: 1.3,
												marginTop: 20,
												opacity: 0.7,
											}}>
											* get an extra discount if paid yearly <br />
											(prices shown without the discount)
										</div>
									</div>
									<a
										href="https://sidemail.io/contact"
										className="button button--bright button--arrowAnimated"
										style={{ width: 210, maxWidth: "100%" }}>
										Contact us
										<IconArrowRight className="u-ml1" width={16} height={16} />
									</a>
								</section>
							) : (
								<section className="pricingBox-plan">
									<h3 className="pricingBox-planName">All-inclusive</h3>

									<p className="pricingBox-planDescirption">
										Send up to {Number(sendingLimit) / 1000}k emails per month.
										Comes with no-code email editor, API, automation, unlimited
										projects, and every other feature we offer. All you need in
										one plan.
									</p>

									<div className="pricingBox-planPrice">
										{pricePlanPremium.hasDiscount && (
											<>${pricePlanPremium.monthlyDiscounted}/mo</>
										)}
										{!pricePlanPremium.hasDiscount && (
											<>${Math.round(pricePlanPremium.monthlyNormal)}/mo</>
										)}
									</div>
									<div className="pricingBox-planPriceDetails">
										when paid yearly or ${pricePlanPremium.monthlyFullPrice}{" "}
										monthly
									</div>

									<a
										href="https://client.sidemail.io/register"
										className="button button--bright button--arrowAnimated"
										style={{ width: 210, maxWidth: "100%" }}>
										Start free trial
										<IconArrowRight className="u-ml1" width={16} height={16} />
									</a>
								</section>
							)}

							<div className="priceBox-benefits">
								<div>
									<CheckMarkIcon /> Send in minutes
								</div>

								<div>
									<CheckMarkIcon /> Always improving
								</div>

								<div>
									<CheckMarkIcon /> Expert support
								</div>

								<div>
									<CheckMarkIcon /> No hidden fees
								</div>
							</div>
						</div>
					</div>
				</header>

				<section className="container">
					<h2 className="">What's included</h2>
					<p className="text-large mb-70">
						Get 5+ tools for one price with Sidemail
					</p>

					<div className="grid grid--alignCenter mb-30">
						<div className="grid-cell grid-cell--notFluid">
							<p className="text-large m-0">All for transactional emails</p>
						</div>

						<div className="grid-cell">
							<hr className="separator m-0" />
						</div>
					</div>

					<ul className="checkListGrid mb-70">
						<li>
							<CheckMarkIcon /> Email API & webhooks
						</li>
						<li>
							<CheckMarkIcon /> No-code email editor
						</li>
						<li>
							<CheckMarkIcon /> Email templates
						</li>
						<li>
							<CheckMarkIcon /> Email sending history
						</li>
						<li>
							<CheckMarkIcon /> Simple DKIM
						</li>
						<li>
							<CheckMarkIcon /> Simple analytics
						</li>
						<li>
							<CheckMarkIcon /> Suppression list
						</li>
						<li>
							<CheckMarkIcon /> Code snippets
						</li>
						<li>
							<CheckMarkIcon /> Dedicated sending IPs
						</li>
						<li>
							<CheckMarkIcon /> Prioritized delivery
						</li>
						<li>
							<CheckMarkIcon /> Simple domain verification
						</li>
					</ul>

					<div className="grid grid--alignCenter mb-30">
						<div className="grid-cell grid-cell--notFluid">
							<p className="text-large m-0">All for email outreach</p>
						</div>

						<div className="grid-cell">
							<hr className="separator m-0" />
						</div>
					</div>

					<ul className="checkListGrid mb-70">
						<li>
							<CheckMarkIcon /> Simple newsletters
						</li>
						<li>
							<CheckMarkIcon /> Email templates
						</li>
						<li>
							<CheckMarkIcon /> Contact groups
						</li>
						<li>
							<CheckMarkIcon /> Unlimited subscribers
						</li>
						<li>
							<CheckMarkIcon /> Simple analytics
						</li>
						<li>
							<CheckMarkIcon /> Image CDN
						</li>
						<li>
							<CheckMarkIcon /> Email automation
						</li>
						<li>
							<CheckMarkIcon /> Schedule email delivery
						</li>
						<li>
							<CheckMarkIcon /> Unsubscribe handling
						</li>
						<li>
							<CheckMarkIcon /> White-label design
						</li>
						<li>
							<CheckMarkIcon /> Open tracking (or turn off)
						</li>
						<li>
							<CheckMarkIcon /> Bounce handling
						</li>
					</ul>

					<div className="grid grid--alignCenter mb-30">
						<div className="grid-cell grid-cell--notFluid">
							<p className="text-large m-0">
								All for email templates & email design
							</p>
						</div>

						<div className="grid-cell">
							<hr className="separator m-0" />
						</div>
					</div>

					<ul className="checkListGrid mb-70">
						<li>
							<CheckMarkIcon />
							No-code email editor
						</li>
						<li>
							<CheckMarkIcon />
							Template properties
						</li>

						<li>
							<CheckMarkIcon />
							Global design
						</li>
						<li>
							<CheckMarkIcon />
							No-code email templates
						</li>
						<li>
							<CheckMarkIcon />
							Upload a logo
						</li>
						<li>
							<CheckMarkIcon />
							Choose color scheme
						</li>
						<li>
							<CheckMarkIcon />
							500+ Google fonts
						</li>
						<li>
							<CheckMarkIcon />
							No code required
						</li>
						<li>
							<CheckMarkIcon />
							Dark mode (@media)
						</li>
						<li>
							<CheckMarkIcon />
							Completely white-label
						</li>
						<li>
							<CheckMarkIcon />
							Real-time preview
						</li>
						<li>
							<CheckMarkIcon />
							Customize preheader
						</li>
						<li>
							<CheckMarkIcon />
							Responsive design
						</li>
						<li>
							<CheckMarkIcon />
							Tested in all email clients
						</li>
					</ul>

					<div className="grid grid--alignCenter mb-30">
						<div className="grid-cell grid-cell--notFluid">
							<p className="text-large m-0">All in one place</p>
						</div>

						<div className="grid-cell">
							<hr className="separator m-0" />
						</div>
					</div>

					<ul className="checkListGrid mb-140">
						<li>
							<CheckMarkIcon />
							Stripe integration
						</li>
						<li>
							<CheckMarkIcon />
							Contact search & filters
						</li>
						<li>
							<CheckMarkIcon />
							Contact properties
						</li>

						<li>
							<CheckMarkIcon />
							Contact API
						</li>
						<li>
							<CheckMarkIcon />
							Subscribe forms
						</li>
						<li>
							<CheckMarkIcon />
							Email reports
						</li>
						<li>
							<CheckMarkIcon />
							Multiple projects
						</li>
						<li>
							<CheckMarkIcon />
							Adjust sending limit
						</li>
					</ul>
				</section>

				<section className="mb-140">
					<div className="container maxWidth-880">
						<div className="grid grid--alignCenter mb-30">
							<div className="grid-cell grid-cell--notFluid">
								<h2 className="m-0">Common questions</h2>
							</div>

							<div className="grid-cell">
								<hr className="separator m-0" />
							</div>
						</div>

						<p className="text-large mb-20">
							Can I adjust my monthly sending limit at any time?
						</p>
						<p className="text-light mt-0 mb-50">
							Yes, adjust your monthly sending limit at any time without penalty
							or hassle. Both increase and decrease changes in the sending limit
							are immediate — no wait for the next billing cycle. When you
							increase the sending limit, we charge you immediately a calculated
							amount based on the previous sending limit and the days left in
							the billing cycle.
						</p>

						<p className="text-large mb-20">
							What happens if I exceed my monthly sending limit?
						</p>
						<p className="text-light mt-0 mb-50">
							If you exceed your monthly sending limit, you will be billed for
							extra usage at the end of your billing cycle. The cost is
							calculated for 1,000 emails, based on your plan pricing. The cost
							calculation: <br />
							Overage cost per 1,000 emails = (Your plan price / Your plan's
							email limit) * 1,000
							<br />
							Furthermore, you can upgrade your plan at any time if you
							anticipate higher sending needs.
						</p>

						<p className="text-large mb-20">
							Will I be charged when my trial ends?
						</p>
						<p className="text-light mt-0 mb-50">
							No. We don't ask for your credit card to start the trial. Only
							enter payment details when you're ready to upgrade. If not, no
							hurt feelings.
						</p>

						<p className="text-large mb-20">Can I cancel at any time?</p>
						<p className="text-light mt-0 mb-50">
							Yes, cancel at any time. There's no minimum contract for Sidemail
							— cancel at any time with one click, no questions asked.
						</p>

						<p className="text-large mb-20">Need a custom plan?</p>
						<p className="text-light mt-0 mb-50">
							Need a higher monthly sending limit? Looking for other
							customization? Let us know about your requirements — we're ready
							to tailor a custom quote. <a href="/contact/">Get in touch</a>.
						</p>
					</div>
				</section>

				<section id="pricing-testimonials">
					<div className="container">
						<div className="testimonialBoxWithBg">
							<StarRating />
							<h3 className="testimonialTitle text-dark">
								Nothing could beat Sidemail
							</h3>
							<p className="text-medium">
								“We were absolutely blown away by how awesome the Sidemail
								offering has been for us at Quicka. We looked at rolling our own
								and leveraging existing providers but nothing could beat
								Sidemail for simplicity and ease of use for beautiful
								transactional emails. Thanks Patrik and the team!“
							</p>
							<TestimonialAutor
								name="Nick Glynn"
								position={
									<span className="text-light">
										co-founder and CTO of{" "}
										<a href="https://www.quickapay.com/">Quicka</a>
									</span>
								}
								imgSrc={nickPhoto}
								imgAlt="Nick Glynn photo"
							/>
						</div>
					</div>
				</section>

				<section className="container maxWidth-800 text-center">
					<h2 className="itemCenter">
						Join businesses that send with Sidemail today
					</h2>
					<p className="text-large itemCenter mb-50">
						Sidemail will enable your team to remove hundreds of lines of code
						that you had to previously maintain yourself, while no longer having
						to deal with broken emails or landing in the spam folder. Try it
						free for 7 days.
					</p>
					<a
						href="https://client.sidemail.io/register"
						className="button button--bright button--arrowAnimated">
						Start trial now
						<IconArrowRight className="u-ml1" width={16} height={16} />
					</a>
				</section>
			</main>
		</Layout>
	);
}

export default Pricing;
