const MONTHLY_PRICE = {
	1000: 19,
	10000: 32,
	40000: 71,
	70000: 110,
	100000: 149,
	250000: 219,
	500000: 369,
	750000: 469,
	1000000: 619,
};

const ANNUAL_PRICE = {
	1000: Math.ceil(228 * 0.75),
	10000: Math.ceil(384 * 0.75),
	40000: Math.ceil(852 * 0.75),
	70000: Math.ceil(1320 * 0.75),
	100000: Math.ceil(1788 * 0.75),
	250000: 1971,
	500000: 3321,
	750000: 4221,
	1000000: 5571,
};

const OVERLIMIT_PRICE = {
	1000: 15.0,
	10000: 7.0,
	40000: 3.6,
	70000: 2.8,
	100000: 2.3,
	250000: 1.6,
};

const DISCOUNT_PERCENTAGE = 50;

export function getPlanPrice({
	planName,
	sendingLimit,
	billingCycle,
	discountRef,
}) {
	function calculateDiscount(twelveMonthFullPrice) {
		if (!discountRef) {
			return null;
		}

		const rate = 100 - DISCOUNT_PERCENTAGE;
		const result = (twelveMonthFullPrice / 100) * rate;

		return Math.round(result * 100) / 100;
	}

	function calculateMonthlyPrice(price) {
		const result = price / 12;
		return Math.round(result * 100) / 100;
	}

	if (planName === "premium") {
		if (billingCycle === "monthly") {
			return {
				normal: MONTHLY_PRICE[sendingLimit],
				discounted: calculateDiscount(MONTHLY_PRICE[sendingLimit]),
				hasDiscount: false,
				overLimit: OVERLIMIT_PRICE[sendingLimit],
			};
		}

		if (billingCycle === "annually") {
			const normalPrice = ANNUAL_PRICE[sendingLimit];
			const oneMonthNormalPrice = normalPrice / 12;

			const oneMonthFullPrice = MONTHLY_PRICE[sendingLimit];
			const twelveMonthFullPrice = MONTHLY_PRICE[sendingLimit] * 12;
			const discounted = calculateDiscount(twelveMonthFullPrice);

			return {
				normal: normalPrice,
				discounted: discounted,
				monthlyFullPrice: oneMonthFullPrice,
				monthlyNormal: oneMonthNormalPrice,
				monthlyDiscounted: calculateMonthlyPrice(discounted),
				twelveMonthFullPrice,
				hasDiscount: Boolean(discountRef),
				overLimit: OVERLIMIT_PRICE[sendingLimit],
			};
		}
	}

	if (planName === "deluxe") {
		if (billingCycle === "monthly") {
			return {
				normal: 849,
				discounted: calculateDiscount(849),
				hasDiscount: false,
				overLimit: OVERLIMIT_PRICE[250000],
			};
		}

		if (billingCycle === "annually") {
			const normalPrice = 7641;
			const oneMonthNormalPrice = normalPrice / 12;

			const oneMonthFullPrice = 849;
			const twelveMonthFullPrice = 849 * 12;
			const discounted = calculateDiscount(twelveMonthFullPrice);

			return {
				normal: normalPrice,
				discounted: discounted,
				monthlyFullPrice: oneMonthFullPrice,
				monthlyNormal: oneMonthNormalPrice,
				monthlyDiscounted: calculateMonthlyPrice(discounted),
				twelveMonthFullPrice,
				hasDiscount: Boolean(discountRef),
				overLimit: OVERLIMIT_PRICE[250000],
			};
		}
	}
}
